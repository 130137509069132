import Alpine from 'alpinejs';
import hljs from 'highlight.js';
Alpine.data('code', () => ({
    codeExamples: {
        lambdaCode: hljs.highlight(`
//LAMBDA
//read each object and for each object read and array of object and their "piece" int value and sum them all.
var totalDepartingPieces = departingFlights.stream()
        .flatMap(flight -> flight.getCargo().getBaggageList().stream())
        .mapToInt(Load::getPieces).sum();
//VS

//NO LAMBDA
int totalDepartingPiecesNoLambda = 0;
for (var flight : departingFlights) {
    for(var baggage : flight.getCargo().getBaggageList()) {
        totalDepartingPiecesNoLambda += baggage.getPieces();
    }
}`,{ language: 'java' }).value,
        javaReflection: hljs.highlight(`
public static <T,R> List<SelectItem<R>> listToSelectItem(
  List<T> list, String label, String value, Class<R> selectItemKeyClass )
  throws NoSuchMethodException, InvocationTargetException, IllegalAccessException {
  List<SelectItem<R>> itemList = new ArrayList<>();
  for (T item : list) {
      R fieldValue = null;
      Method methodValue = item.getClass().getMethod("get" + getSetterGetterName(value));
      fieldValue = (R) methodValue.invoke(item);
      Method methodLabel = item.getClass().getMethod("get" + getSetterGetterName(label));
      Object campoLabelValor = methodLabel.invoke(item);
      itemList.add(new SelectItem<R>(fieldValue,String.valueOf(campoLabelValor)));
  }
  return itemList;
}
public static void main(String[] arg) throws InvocationTargetException, NoSuchMethodException, IllegalAccessException {
  List<Country> countries = List.of(new Country(1, "ES", "Spain"),
          new Country(2, "US", "USA"));
  System.out.println(countries);
  List<SelectItem<Integer>> selectItems = listToSelectItem(countries, "id", "name", Integer.class);
  System.out.println(selectItems);
}`,{ language: 'java' }).value,
        javaReflection_2: hljs.highlight(`
[Country(id=1, name=ES, description=Spain), Country(id=2, name=US, description=USA)]
[SelectItem{id=ES, value='1'}, SelectItem{id=US, value='2'}]`,{ language: 'text' }).value,
        integrationFTP: hljs.highlight(`
@Data
public class RegistrationLPR {
  /**
    * (start character)
    */
  @CsvBindByPosition(position = 0)
  @Size(min = 1, max = 1, message = "initial character should contain 1 character")
  private String n = "N";
  /**
    * (must be unique) (Up to 10 characters)
    */
  @CsvBindByPosition(position = 1)
  @Size(min = 1, max = 10, message = "voucher number must be between 1 and 10 characters")
  private String voucherNumber;
  /**
    * (Up to 10 characters)
    */
  @CsvBindByPosition(position = 2)
  @Size(min = 1, max = 10, message = "voucher product code must be between 1 and 10 characters")
  private String voucherProductCode;
  /**
    * (Up to 20 characters)
    */
  @CsvBindByPosition(position = 3)
  @Size(max = 20, message = "patronSurname code overpass 20 characters")
  private String patronSurname;
}`,{ language: 'java' }).value,
        integrationFTP_2: hljs.highlight(`
String createFileContentToSent(List<RegistrationLPR> registrationLPRSToBeSent) throws CsvRequiredFieldEmptyException, CsvDataTypeMismatchException {
  Writer writer = new StringWriter();
  StatefulBeanToCsv<RegistrationLPR> sbc = new StatefulBeanToCsvBuilder<RegistrationLPR>(writer)
          .withQuotechar(CSVWriter.NO_QUOTE_CHARACTER)
          .withSeparator(CSVWriter.DEFAULT_SEPARATOR)
          .withLineEnd("\r\n")
          .build();

  sbc.write(registrationLPRSToBeSent);
  return writer.toString();
}`,{ language: 'java' }).value,
        integrationFTP_3: hljs.highlight(`
@Override
public void addRegister(RegisterCreation registerCreation) {
  RegistrationLPR registrationLPR = mapper.fromRegisterCreation(registerCreation);
  //validation using javax.validation
  objectValidator.validate(registrationLPR);
  service.addRegistryReadyToBeSent(registrationLPR)
}`,{ language: 'java' }).value,
        integrationFTP_4: hljs.highlight(`
public void sendToSftp(String fileContent) throws JSchException, SftpException {
  ChannelSftp channelSftp = SftpConfiguration.createChannelSftp();
  try {
      InputStream fileContentInputStream = IOUtils.toInputStream(fileContent);
      channelSftp.connect();
      //send register file
      channelSftp.put(fileContentInputStream, remoteDir + REGISTRATION_FILE_NAME);
  } finally {
      SftpUtil.closeSftpConnection(channelSftp);
  }
}`,{ language: 'java' }).value,
        testingEmail: hljs.highlight(`
@SpringBootTest
@ActiveProfiles("test")
@AutoConfigureWebTestClient
class AdministratorControllerTest {
    @Autowired
    private MongoAdministratorClient mongoAdministratorClient;
    @Autowired
    private WebTestClient webClient;
    @RegisterExtension
    static GreenMailExtension greenMail = new GreenMailExtension(ServerSetupTest.SMTP)
            .withConfiguration(GreenMailConfiguration.aConfig().withUser("test", "password"))
            .withPerMethodLifecycle(false);
    @Test
    @DisplayName("should send forgotPassword")
    void should_send_forgotPassword() throws Exception {
        // GIVEN
        SendForgotPasswordDto sendForgot = mockSendForgotPasswordDto();
        AdministratorDocument adminDocument = mockAdministratorDocument();
        mongoAdministratorClient.save(adminDocument).block();
        String url = String.format("%s/%s", ADMINISTRATOR_API, SEND_FORGOT_PASSWORD_API);
        // WHEN
        webClient.post()
                .uri(url)
                .body(Mono.just(sendForgot), SendForgotPasswordDto.class)
                .exchange()
                // THEN response
                .expectStatus().is2xxSuccessful();
        // THEN database
        AdministratorDocument adminDoc = mongoAdministratorClient.findById(adminDocument.getId().toString()).block();
        assertThat(adminDoc).isNotNull();
        assertThat(adminDoc.getForgetPasswordToken()).isNotNull();
    }
}`,{ language: 'java' }).value,
        threadExecutor: hljs.highlight(`
@Component
@RequiredArgsConstructor
@Slf4j
public class ProccesorDataScheduler {
    private final Processor processor;

    /**
      * Process data and send them to different systems
      */
    @Scheduled(cron = "\${processor.cron.cronToSendSftpFile}")
    public void gatherDataAndSend() {
      processor.sendDataToSystems();
    }
}`,{ language: 'java' }).value,
    threadExecutor_2: hljs.highlight(`
@RequiredArgsConstructor
@Service
@Slf4j
public class ProcessorImpl implements Processor {
    private static final int MAX_THREADS_NUMBER = 4;

    @Override
    void sendDataToSystems() {
        ExecutorService executor = Executors.newFixedThreadPool(MAX_THREADS_NUMBER);

        //job work
        Callable<String> jobWork = this::executeJob;
        executor.submit(jobWork);

        //shutdown
        executor.shutdown();
        try {
            if (!executor.awaitTermination(1, TimeUnit.MINUTES)) {
                executor.shutdownNow();
            }
        } catch (InterruptedException e) {
            log.error("Error while waiting for executor termination", e);
            executor.shutdownNow();
        }
    }

    String executeJob() {
        //code here
        return "codeResult";
    }
}`,{ language: 'java' }).value,
        independientJdkPom: hljs.highlight(`
<plugins>
    <plugin>
    <!-- generation of zip program: this will generate a zip with the whole app in target -->
    <execution>
        <id>zipApplication</id>
        <phase>package</phase>
        <goals>
            <goal>single</goal>
        </goals>
        <configuration>
            <appendAssemblyId>false</appendAssemblyId>
            <descriptors>
                <!-- configuration of how generate the zip -->
                <descriptor>src/main/assembly/zip.xml</descriptor>
            </descriptors>
        </configuration>
    </execution>
    </plugin>
</plugins>
        `,{ language: 'xml' }).value,
        independientJdkZip: hljs.highlight(`
<plugins>
    <plugin>
    <!-- generation of zip program: this will generate a zip with the whole app in target -->
    <execution>
        <id>zipApplication</id>
        <phase>package</phase>
        <goals>
            <goal>single</goal>
        </goals>
        <configuration>
            <appendAssemblyId>false</appendAssemblyId>
            <descriptors>
                <!-- configuration of how generate the zip -->
                <descriptor>src/main/assembly/zip.xml</descriptor>
            </descriptors>
        </configuration>
    </execution>
    </plugin>
</plugins>
        `,{ language: 'xml' }).value,
        openApiValidationDefinition: hljs.highlight(`
/flight-info:
get:
    tags:
    - flight-controller
    summary: getFlightInfo
    operationId: getFlightInfo
    parameters:
    - name: flightNumber
        in: query
        description: flight number
        required: true
        schema:
        type: integer
        example: 7539
    - name: flightDate
        in: query
        description: flight date
        required: true
        schema:
        type: string
        format: date-time
        example: "2019-02-19T05:40:20-01:00"
    responses:
    '200':
        content:
        application/json:
            schema:
            "$ref": "#/components/schemas/FlightInfoDto"
    '401':
        description: Bad Parameter
        content:
        application/json:
            schema:
            "$ref": "../smart-aviation-api.yml#/components/schemas/GenericErrorResponseDto"
    '404':
        description: Not Found
        content:
        application/json:
            schema:
            "$ref": "../smart-aviation-api.yml#/components/schemas/GenericErrorResponseDto"
            `,{ language: 'xml' }).value
        ,
    cachingConfiguration: hljs.highlight(`
@Service
@Transactional(readOnly = true)
public class ProductPriceServiceImpl implements ProductPriceService {
    //this will connect with database which is very expensive.
    @Autowired
    private PriceRepository priceRepository;
    
    //we will cache by the filter (complex object)
    @Cacheable(cacheNames="productPriceCache", key="#filterProductPrice")
    @Override
    public ProductPrice findByFilterProductPrice(FilterProductPrice filterProductPrice) {
        return priceRepository.findByFilter(filterProductPrice);
    }
}`,{ language: 'java' }).value,
        cachingConfiguration_2: hljs.highlight(`
<config xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns="http://www.ehcache.org/v3"
    xmlns:jsr107="http://www.ehcache.org/v3/jsr107"
    xsi:schemaLocation="
        http://www.ehcache.org/v3 http://www.ehcache.org/schema/ehcache-core-3.0.xsd
        http://www.ehcache.org/v3/jsr107 http://www.ehcache.org/schema/ehcache-107-ext-3.0.xsd">

    <cache alias="productPriceCache">
        <key-type>com.ricardocreates.dto.FilterProductPrice</key-type>
        <value-type>com.ricardocreates.model.ProductPrice</value-type>
        <expiry>
            <!-- we should increase this seconds in a real app -->
            <ttl unit="seconds">30</ttl>
        </expiry>
        <resources>
            <heap unit="entries">2</heap>
            <offheap unit="MB">10</offheap>
        </resources>
    </cache>
</config>`,{ language: 'xml' }).value,
        loggingMavenLibrary: hljs.highlight(`
<!-- we use slf4j api, so in our library we will work with the interface
 and in the app will work with the implementation of the interface -->
<dependency>
    <groupId>org.slf4j</groupId>
    <artifactId>slf4j-api</artifactId>
    <version>\${slf4j-api.version}</version>
</dependency>
<!-- we use slf4j implementation in our library but only for tests not in the final jar -->
<dependency>
    <groupId>org.slf4j</groupId>
    <artifactId>slf4j-simple</artifactId>
    <version>\${slf4j-api.version}</version>
    <scope>test</scope>
</dependency>
        `,{ language: 'xml' }).value,
        loggingMavenApp: hljs.highlight(`
<!--log4j2 -->
<dependency>
    <groupId>org.apache.logging.log4j</groupId>
    <artifactId>log4j-api</artifactId>
    <version>\${log4j2.version}</version>
</dependency>
<!-- binding log4j2 with slf4j -->
<dependency>
    <groupId>org.apache.logging.log4j</groupId>
    <artifactId>log4j-slf4j-impl</artifactId>
    <version>\${log4j2.version}</version>
</dependency>
        `,{ language: 'xml' }).value,
        scssLoopExample: hljs.highlight(`
//margins
$marginSizes: ("tiny": 0.25em, "small": 0.5em, "large": 1em, "extra-large": 1.5em);
@each $name, $size in $marginSizes {
    .margin-top-bot-#{$name} {
    margin-top: $size;
    margin-bottom: $size;
    }
}
        `,{ language: 'scss' }).value,
        scssLoopResult: hljs.highlight(`
.margin-top-bot-tiny {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
.margin-top-bot-small {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.margin-top-bot-large {
    margin-top: 1em;
    margin-bottom: 1em;
}
.margin-top-bot-extra-large {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
        `,{ language: 'css' }).value,
    },
    codeMenu: [
        {
            id: "group1",
            items: [
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                },
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                }
            ]
        },
        {
            id: "group2",
            items: [
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                },
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                }
            ]
        }
    ]
}));