
import Alpine from 'alpinejs';

Alpine.data('portfolio', () => ({
    projects: [
        {
            id: "parkManager",
            miniature: "public/projects/park-manager/parkManager-miniature.png",
            title: "Park Manager",
            description: `Car Manager Application, using microservices to operate with user transactions,
                             payment gateways, and React in the front end`,
            subText: "Created at June of 2023"
        },
        {
            id: "gallaCiencia",
            miniature: "public/projects/galla-ciencia/galla-ciencia-miniatura.png",
            title: "Galla Ciencia",
            description: `Tailor-made web stores, online literary magazines, offer management,
                            order management, card payments, or bank transfers,
                            order management with login security access.`,
            subText: "Created at June of 2015"
        },
        {
            id: "zanori",
            miniature: "public/projects/zanori/zanori-miniatura.png",
            title: "Zanori",
            description: `Tailor-made web recipes online with the possibility of editing,
                 creating, eliminating recipes with user comments.`,
            subText: "Created at July of 2016"
        },
        {
            id: "sigue",
            miniature: "public/projects/sigue-project/sigue-expedient.jpg",
            title: "Call center \"Sigue\"",
            description: `Call centre against abuse. Grails Angular web.
                            Application developed for the government to fight abuse against women`,
            subText: "Created at March of 2019"
        },
        {
            id: "tempo",
            miniature: "public/projects/tempo-project/tempo-manual.png",
            title: "Research professors manager",
            description: `
                Website for managing research professors.
                Manages professors's project schedules
            `,
            subText: "Created at March of 2018"
        },
        {
            id: "plantMonitor",
            miniature: "public/projects/planta-arduino/planta-arduino-miniatura.png",
            title: "Plant monitor",
            description: `
                Ionic Plant Monitor using Arduino and Node.js. 
                Using embedded systems, sensors, and IoT communication.
            `,
            subText: "Created at March of 2015"
        },
        {
            id: "translator",
            miniature: "public/projects/translator/translator-cambridge.png",
            title: "Translator",
            description: `JavaFX and Java 17 modular translator app.
                            Integration with translation services, rich user interface.`,
            subText: "Created at Januray of 2022"
        },
        {
            id: "resizePlugin",
            miniature: "public/projects/resize-plugin/resize-final-result.png",
            title: "Webpack image resize plugin",
            description: `
                Webpack image resize plugin. Allow getting several smaller versions from one image to optimise web traffic.
            `,
            subText: "Created at March of 2020"
        },
        {
            id: "taskManager",
            miniature: "public/projects/sgpm/login-miniatura.jpg",
            title: "Task Manager",
            description: `Business application in charge of managing worker tasks by customer,
                 building and plant, allows the option to assign tasks, rate them, and see the status in which they are and their corresponding
                 managers and executors of these.`,
            subText: "Created at September of 2016"
        },
        {
            id: "minesweeper",
            miniature: "public/projects/buscaminas/buscaminas-miniatura.png",
            title: "Minesweeper",
            description: `Android minesweeper app, using Android SDK, complex algorithm to search mines.`,
            subText: "Created at March of 2014"
        },
    ]
}));