
import Alpine from 'alpinejs';
// eslint-disable-next-line no-unused-vars
const DEFAULT_ICON = "public/techs/icons/default-icon.png";
Alpine.data('skills', () => ({
    search: '',
    get filteredFrontSkills() {
        console.log(this.search);
        return this.frontEnd.filter(
            skill => {
                return skill.title.toUpperCase().includes(this.search.toUpperCase())
            }
        )
    },
    get filteredBackSkills() {
        return this.backEnd.filter(
            skill => {
                return skill.title.toUpperCase().includes(this.search.toUpperCase())
            }
        )
    },
    frontEnd: [
        {title: "Javascript", image: "public/techs/icons/javascript.svg"},
        {title: "React", image: "public/techs/icons/react.svg"},
        {title: "React Material", image: "public/techs/icons/react-material.svg"},
        {title: "Angular", image: "public/techs/icons/angular.svg"},
        {title: "Angular Material", image: "public/techs/icons/angular-material.svg"},
        {title: "Typescript", image: "public/techs/icons/typescript.svg"},
        {title: "Jquery", image: "public/techs/icons/jquery.svg"},
        {title: "Jquery UI", image: "public/techs/icons/jquery-ui.svg"},
        {title: "RxJS", image: "public/techs/icons/rxjs.png"},
        {title: "Redux", image: "public/techs/icons/redux.svg"},
        {title: "Jest", image: "public/techs/icons/jest.svg"},
        {title: "Jasmine", image: "public/techs/icons/jasmine.svg"},
        {title: "Scss", image: "public/techs/icons/scss.svg"},
        {title: "Css", image: "public/techs/icons/css.svg"},
        {title: "HTML5", image: "public/techs/icons/html5.svg"},
        {title: "Ionic", image: "public/techs/icons/ionic.svg"},
        {title: "Bootstrap", image: "public/techs/icons/bootstrap.svg"},
        {title: "Webpack", image: "public/techs/icons/webpack.svg"},
        {title: "Gulp", image: "public/techs/icons/gulp.svg"},
        {title: "Axios", image: "public/techs/icons/axios.png"},
        {title: "esLint", image: "public/techs/icons/eslint.svg"},
        {title: "JSF", image: "public/techs/icons/jsf.png"},
        {title: "Primefaces", image: "public/techs/icons/primefaces.png"},
        {title: "Alpinejs", image: "public/techs/icons/alpine.svg"},
        {title: "React testing library", image: "public/techs/icons/testing-library.png"},
        {title: "npm", image: "public/techs/icons/npm.png"},
        {title: "Lodash", image: "public/techs/icons/lodash.svg"}
    ],

    backEnd: [
        { title: "Java", image: "public/techs/icons/java.svg"},
        { title: "Spring", image: "public/techs/icons/spring.svg"},
        { title: "Spring Boot", image: "public/techs/icons/spring-boot.svg"},
        { title: "Java EE", image: "public/techs/icons/java-ee.png"},
        { title: "Spring Cloud", image: "public/techs/icons/springcloud.jpg"},
        { title: "Spring WebFlux", image: "public/techs/icons/webflux.png"},
        { title: "Spring security", image: "public/techs/icons/spring-security.jpg"},
        { title: "Spring data", image: "public/techs/icons/spring-data.png"},
        { title: "Spring data JPA", image: "public/techs/icons/spring-data-jpa.png"},
        { title: "Spring data MongoDB", image: "public/techs/icons/spring-data.mongodb.png"},
        { title: "Spring cloud contract", image: "public/techs/icons/spring-cloud-contract.png"},
        { title: "Spring cloud feign", image: "public/techs/icons/feing.png"},
        { title: "Maven", image: "public/techs/icons/maven.png"},
        { title: "Jasper Report", image: "public/techs/icons/jasper-report2.png"},
        { title: "SOAP", image: "public/techs/icons/soap.png"},
        { title: "Grails", image: "public/techs/icons/grails.svg"},
        { title: "Groovy", image: "public/techs/icons/groovy.svg"},
        { title: "Gradle", image: "public/techs/icons/gradle.png"},
        { title: "SQL", image: "public/techs/icons/oraclesql.png"},
        { title: "Hibernate", image: "public/techs/icons/hibernate.svg"},
        { title: "MongoDB", image: "public/techs/icons/mongodb.svg"},
        { title: "Open API", image: "public/techs/icons/openapi.svg"},
        { title: "Junit5", image: "public/techs/icons/junit5.png"},
        { title: "Mockito", image: "public/techs/icons/mockito.png"},
        { title: "Hamcrest", image: "public/techs/icons/hamcrest.jpg"},
        { title: "Selenium", image: "public/techs/icons/selenium.svg"},
        { title: "Test Container", image: "public/techs/icons/test-container.png"},
        { title: "Kafka", image: "public/techs/icons/kafka.svg"},
        { title: "Java FX", image: "public/techs/icons/javafx.png"},
        { title: "Jenkins", image: "public/techs/icons/jenkins.svg"},
        { title: "Git", image: "public/techs/icons/git.svg"},
        { title: "GitLab", image: "public/techs/icons/gitlab.svg"},
        { title: "GitHub", image: "public/techs/icons/github.png"},
        { title: "Assertj", image: "public/techs/icons/asserj.png"},
        { title: "Azure", image: "public/techs/icons/azure.svg"},
        { title: "Cucumber", image: "public/techs/icons/cucumber.png"},
        { title: "Liquid Base", image: "public/techs/icons/liquidbase.png"},
        { title: "EhCache", image: "public/techs/icons/ehcache.png"},
        { title: "Redis", image: "public/techs/icons/redis.png"},
        { title: "gRPC", image: "public/techs/icons/grpc.png"},
        { title: "PHP", image: "public/techs/icons/php.svg"},
        { title: "Symfony", image: "public/techs/icons/symfony.svg"}
    ],
}));